
.price-container-lunch {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 90px;
    margin: 20px
  }

    .price-list-lunch {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1080px;
        margin-top: 20px;
    }
    
    .price-item-lunch {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px;
        border-bottom: 1px solid #ccc;
    }
    
    .price-item:hover {
        background-color: #f5f5f5;
        cursor: pointer;
        }
        
    
    .price-item-name-lunch {
        flex-grow: 1;
        margin-right: 16px;
        text-transform: lowercase;
    }

    .price-item-name-lunch::first-letter {
        text-transform: uppercase;
      }
    
    .price-item-price-lunch {
        font-weight: bold;
    }

    .price-item-description-lunch {

    }
    
    @media (max-width: 760px) {
        .price-list {
            width: 90%;
            
        }

        .price-list-lunch {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 1080px;
            margin-top: 20px;
        }
        
        .price-item-lunch {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 8px;
            border-bottom: 1px solid #ccc;
        }
        
        .price-item:hover {
            background-color: #f5f5f5;
            cursor: pointer;
            }
            
        
        .price-item-name-lunch {
            flex-grow: 1;
            margin-right: 16px;
        }
        
        .price-item-price-lunch {
            font-weight: bold;
        }
    
        .price-item-description-lunch {
            display: none
        }
    }
    
        