.gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 90px;
}

.gallery-images-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  width: 100%;
  max-width: 1080px;
  aspect-ratio: 1080/337.5;
  overflow-y: auto;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-image:hover {
  opacity: 0.7;
  cursor: pointer;
}

.selected-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.selected-image {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.arrow-container {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.arrow-container:hover {
    transform: scale(1.1);
}

.arrow-container-left {
  left: 1rem;
}

.arrow-container-right {
  right: 1rem;
}


@media (max-width: 500px) {

  .gallery-images-container {
    display: block;
    grid-template-columns: none;
    grid-gap: none;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 1080/675;
    overflow-y: auto;
  }
  
  .gallery-image {
    width: 100%;
    height: 100%;
  }
  
  .selected-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

}