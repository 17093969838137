@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.home-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-heading {
    order: 1;
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    margin: 0px;
    margin-top: 50px;
}

.home-text1 {
    order: 2; 
    font-weight: 200;
    opacity: 0.8;
    font-family: "Quattrocento",serif;
}

.opening-text {
    order: 3;
}

.book-button {
    order: 4;
    border: none;
    background-color: #4F6133;
    color: white;
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
    padding: 18px 54px;
    border-radius: 5px;
    text-decoration: none;
    transition: ease-in-out 0.2s;
    margin-top: 20px;
}

.book-button:hover {
    transition: ease-in-out 0.2s;
    opacity: 0.6;
}

.home-image {
    order: 5;
    margin-top: 30px;
    width: 70%; 
    max-height: 480px;
}

.n9wygc {
    margin-top: 30px;
    display: block;
    height: auto;
    width: 100%;
}

.BiTRX {
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1080px;
    -webkit-box-ordinal-group: 1;
    order: 5;
}

@media (max-width: 640px) {

    .n9wygc {
        margin-top: 0px;
        display: block;
        height: auto;
        width: 100%;
    }

    .BiTRX {
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 1080px;
        -webkit-box-ordinal-group: 1;
        order: 1;
    }

    .home-image {
        order: 1;
        margin-top: 0;
        min-width: 0;
        width: 100vw;  
        height: auto;
        max-height: 250px;
    
    }
    
    .home-heading {
        order: 2;
        margin-top: 30px;
        font-size: 1.8rem;
    }

    .home-text1 {
        order: 3;  
    }
    
    .opening-text {
        order: 4;
    }

    .book-button {
        order: 5;
    }
}