@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
  overflow-y: auto;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 0 5px grey;
}

.navbar-text {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    min-width: 22px;
    overflow: hidden;
    padding: 0 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    background-color: transparent;
    border: none;
}

.navbar-text:hover {
  opacity: 0.5;
  cursor: pointer;
}

.menu-icon {
  display: block;
  cursor: pointer;
}

.nav-elements {
  position: fixed;
  left: 0;
  top: 60px;
  background-color: #fff;
  width: 0px;
  height: 100vh;
  transition: all 0.3s ease-in;
  overflow: hidden;
  z-index: 100;
  box-shadow: 0 0 5px grey;
}

.nav-elements.active {
  width: 270px;
}

.nav-elements ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  flex-direction: column;
}

.nav-elements ul li {
  margin-right: unset;
  margin-top: 22px;
}

.nav-elements ul a:hover {
  cursor: pointer;
  opacity: 0.7;
}

.icons-container {
  margin-right: 30px;
}

.navbar-icon {
  color: #000;
  margin-right: 30px;
  margin-left:0px;
  text-decoration: none;
}

.navbar-icon:hover {
  opacity: 0.5;
}

@media (max-width: 640px) {

  .navbar-icon {
    display: none;
  }

  .container {
    justify-content: flex-start;
  }

  .navbar {
      display: block;
  }   

}