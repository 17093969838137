.lunch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 90px;
}

.home-text1 {
    font-size: 1.3rem;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    .lunch-container {
      margin-left: auto;
      margin-right: auto;
    }
  }