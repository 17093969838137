.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 90px;
}

.price-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1080px;
    margin-top: 20px;
  }
  
  .price-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid #ccc;
  }

  .price-item:hover {
    background-color: #f5f5f5;
    cursor: pointer;
   }
   
  
  .price-item-name {
    flex-grow: 1;
    margin-right: 16px;
  }
  
  .price-item-price {
    font-weight: bold;
  }
  
  @media (max-width: 760px) {
    .price-list {
        width: 90%;
        
    }
  }

  