.info-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 90px;
    
}

.iframe-info {
    width: 100%;
    height: auto;
    max-width: 1080px;
    aspect-ratio: 1080/675;
    border: none;
}
  
.info-details-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 60px;
    width: 100%;
    max-width: 1500px;
}

.info-details-header {
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
}

.info-details-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info-button {
    border: none;
    background-color: #4F6133;;
    color: white;
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
    padding: 18px 54px;
    border-radius: 5px;
    text-decoration: none;
    transition: ease-in-out 0.2s;
    margin-top: 10px;
}

.info-button:hover {
    opacity: 0.6;
}

.socials-logo-container {
    display: flex;
    width: 100%;
}

.socials-logo {
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.socials-logo:hover {
    opacity: 0.6;
    cursor: pointer;
}



@media (max-width: 760px) {

    .info-details-container {
        flex-direction: column;
    }

    .info-details {
        margin-bottom: 30px;
    }

    .info-details-header {
        justify-content: center;
    }
    
    .info-details-content {
        align-items: center;
    }

    .socials-logo-container {
        justify-content: center;
    }

}


.title-container {
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 15px;
    background-color: transparent;
}

.title-text {
    font-size: 1rem;
    font-weight: bold;
    color: black;
    margin: 0;
    text-transform: uppercase;
}

.title-stripe {
    position: relative;
    height: 1.5px;
    width: 70px;
    color: black;
    top: 12px
}

.title-stripe-left {
    right: 20px;
    background-color: black;
}

.title-stripe-right {
    left: 20px;
    background-color: black;
}